import axios from 'axios';
import User from '@/domain/core/User';

// const [username, password] = process.env.HTTP_AUTH.split(':');

export const backendApi = axios.create({
	baseURL: '/backApi/',
	// auth: { username, password },
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
	},
});

backendApi.interceptors.request.use((config) => {
	if (typeof window === 'undefined') {
		return config;
	}

	const params = new URLSearchParams(window.location.search);

	const isInternational = window.location.pathname.startsWith('/international');
	const legalStatusId = params.get('legalStatusId');
	const isLegal = legalStatusId === '1';

	if (isInternational) {
		// eslint-disable-next-line no-param-reassign
		config.headers['x-international'] = true;
	}
	if (isLegal) {
		// eslint-disable-next-line no-param-reassign
		config.headers['x-legal'] = true;
	}

	if (User?.currentRole?.id) {
		// eslint-disable-next-line no-param-reassign
		config.headers['x-current-role-id'] = User.currentRole.id;
	}

	return config;
});

export const backendInnerApi = axios.create({
	baseURL: process.env.INNER_BACK_HOST,
	// auth: { username, password },
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
	},
});
