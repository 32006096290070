import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import User from '@/domain/core/User';

import PageLoader from '../../loaders/PageLoader';

interface IProps {
	children: ReactElement;
}

const TrackHelmet: React.FC<IProps> = observer(({ children }) => {
	const { push, asPath, pathname } = useRouter();

	const canView = () => {
		if (User.isLeader || User.isTopManager) {
			return true;
		}
		if (pathname.startsWith('/international')) {
			return User.isInternational;
		}
		return !User.isInternational;
	};

	React.useLayoutEffect(() => {
		if (!canView()) {
			const getReplacedPath = (p: string) =>
				User.isInternational ? `/international${p}` : p.replace('international/', '');
			const replacedPath = pathname.includes('[') ? getReplacedPath(asPath) : getReplacedPath(pathname);
			push(replacedPath).catch(console.error);
		}
	});

	if (canView()) {
		return children;
	}

	return <PageLoader />;
});

export default TrackHelmet;
