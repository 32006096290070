import { makeAutoObservable, runInAction } from 'mobx';

import { SELECTED_ROLE } from '@/const/storageKeys';
import { UserRoles } from '@/data/user/role';
import AbilityStore, { IAbilityStore } from '@/domain/core/auth/AbilityStore';
import { TCompetencies } from '@/types/expertise';
import { TUser, TUserProfile, TUserRole } from '@/types/user';
import { backendApi } from '@/utils/api';

export interface IUserStore {
	profile?: TUser['profile'];
	abilityStore;
	userReadyStatus: 'loading' | 'success' | 'error';
	id: number;
	isAdmin: boolean;
	isLeader: boolean;
	updateProfile: (newAvatar: TUserProfile) => void;
	checkUserConsents: () => void;
	hasConsent: boolean;
	subRoles?: TUserRole[];
	role: TUserRole;
	internationalProjectsAvailable: boolean;
	changeUserRole(roleId: string): void;
}

export interface IAuthResponse {
	role?: TUserRole;
	roles?: Array<TUserRole & { isMain: boolean }>;
	profile?: TUser['profile'];
	id: number;
}

interface AvailableProjectsResponse {
	hasInternationalProjects: boolean;
	hasLeaderProjects: boolean;
}

export type ConsentResponse = Array<{ type: string; hasConsent: boolean }>;

class User implements IUserStore {
	id: number;

	abilityStore: IAbilityStore;

	profile: TUser['profile'] = {} as TUser['profile'];

	role: TUserRole;

	userReadyStatus: 'loading' | 'success' | 'error' = 'loading';

	hasConsent = true;

	internationalProjectsAvailable = false;

	leaderProjectsAvailable = false;

	subRoles: TUserRole[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get isGlobalAdmin() {
		return this.role?.name === UserRoles.GlobalAdmin;
	}

	get isAdmin() {
		return (
			this.role?.name === UserRoles.GlobalAdmin ||
			this.role?.name === UserRoles.Admin ||
			this.role?.name === UserRoles.ThemeAdmin ||
			this.role?.name === UserRoles.InternationalAdmin
		);
	}

	get isImportAdmin() {
		return this.role?.name === UserRoles.ImportAdmin;
	}

	get isSecurityAdmin() {
		return this.role?.name === UserRoles.SecurityAdmin;
	}

	get isTracker() {
		return (
			this.role?.name === UserRoles.Tracker ||
			this.role?.name === UserRoles.ThemeTracker ||
			this.role?.name === UserRoles.InternationalTracker ||
			this.role?.name === UserRoles.InternationalTrackerExpert
		);
	}

	get isLeader() {
		return this.role?.name === UserRoles.Leader || this.role?.name === UserRoles.InternationalLeader;
	}

	get isHeadTracker() {
		return this.role?.name === UserRoles.HeadTracker;
	}

	get isTopManager() {
		return this.role?.name === UserRoles.TopManager;
	}

	get isExpert() {
		return (
			this.role?.name === UserRoles.Expert ||
			this.role?.name === UserRoles.InternationalExpert ||
			this.role?.name === UserRoles.ThemeExpert ||
			this.role?.name === UserRoles.InternationalTrackerExpert
		);
	}

	get isEmployee() {
		return this.role?.name === UserRoles.Employee;
	}

	get isManager() {
		return this.role?.name === UserRoles.Manager;
	}

	get inAdminGroup() {
		return this.isAdmin || this.isTracker || this.isHeadTracker;
	}

	get isInternational() {
		return this.role?.name.includes('international');
	}

	async init() {
		return this.fetchUserData().catch(console.error);
	}

	get currentRole() {
		return this.role;
	}

	updateProfile = (newProfile: TUserProfile) => {
		this.profile = newProfile;
	};

	getInitialRedirectPath() {
		if (!this.role?.name) return '/';

		switch (this.role.name) {
			case UserRoles.Leader:
				return '/projects';
			case UserRoles.InternationalLeader:
				return '/international/projects';
			case UserRoles.ThemeExpert:
			case UserRoles.Expert:
				return `/experts/${this.id}/expertises/new`;
			case UserRoles.InternationalExpert:
				return `/international/experts/${this.id}/expertises/new`;
			case UserRoles.InternationalTracker:
			case UserRoles.InternationalTrackerExpert:
			case UserRoles.InternationalAdmin:
				return '/international/projects';
			default:
				return '/projects';
		}
	}

	async fetchUserData() {
		if (typeof window === 'undefined') return;
		const selectedRole = localStorage.getItem(SELECTED_ROLE);

		try {
			const { data } = await backendApi.get<IAuthResponse>('/users/my-profile');

			if (data.role?.name === UserRoles.Leader || data.role?.name === UserRoles.InternationalLeader) {
				const { data: availableProjects } = await backendApi.get<AvailableProjectsResponse>(
					'/projects/available'
				);

				if (availableProjects?.hasInternationalProjects) {
					this.internationalProjectsAvailable = true;
				}

				if (availableProjects?.hasLeaderProjects) {
					this.leaderProjectsAvailable = true;
				}
			}

			let role = data?.roles?.find((x) => !!x.isMain) ?? data.role;

			if (selectedRole) {
				role = data?.roles?.find((x) => x.id === Number(selectedRole)) || role;
			}

			runInAction(() => {
				this.profile = data.profile;
				this.role = role ?? data.role;
				this.id = data.id;
				this.abilityStore = new AbilityStore(this.role);
				this.userReadyStatus = 'success';
				this.subRoles = data?.roles ?? [];
			});
		} catch (error) {
			const isInternational = window.location.pathname.startsWith('/international');

			this.abilityStore = new AbilityStore({
				name: isInternational ? UserRoles.InternationalLeader : UserRoles.Leader,
			});
			this.userReadyStatus = 'error';

			console.error('Ошибка при авторизации', error);
		}
	}

	validateExpert = async () => {
		if (!this.isExpert) return false;

		try {
			const response = await backendApi.get<{ competencies: TCompetencies[] }>(`/experts/check/user`);
			return response.data.competencies?.length === 0;
		} catch (e) {
			return true;
		}
	};

	checkUserConsents = async () => {
		try {
			const { data } = await backendApi.get<ConsentResponse>('/consents');
			const requiredConsent = data.find((item) => item.type === 'personalDataProcessing');
			const hasConsent = !requiredConsent || !requiredConsent.hasConsent;
			this.setConsent(!hasConsent);
		} catch (e) {
			console.error(e);
		}
	};

	setConsent = (bool: boolean) => {
		this.hasConsent = bool;
	};

	changeUserRole = (roleId: number | string) => {
		const newRole = this.subRoles.find((x) => x.id === +roleId);

		if (newRole) {
			this.role = newRole;
			this.abilityStore = new AbilityStore(this.role);
		}
	};
}

export default new User();
