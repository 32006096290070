import { Ability, AbilityBuilder } from '@casl/ability';

import { RolePermissions } from '@/data/user/roles-permissions';
import { TUserRole } from '@/types/user';

export interface IAbilityStore {
	role: TUserRole;
	ability: Ability;
	builder: AbilityBuilder<Ability>;
}

class AbilityStore implements IAbilityStore {
	role: TUserRole;

	builder: AbilityBuilder<Ability>;

	get ability() {
		RolePermissions[this.role.name].permissions?.forEach((item) => {
			this.builder.can(item.action, item.subject, item.condition);
		});

		return this.builder.build();
	}

	constructor(role: TUserRole) {
		this.role = role;
		this.builder = new AbilityBuilder(Ability);
	}
}

export default AbilityStore;
